<template>
	<div>
		<div v-if="!loading && data.length > 0">
			<div
				v-for="bannerInfo in data"
				:key="bannerInfo.id">
				<div class="row">
					<div class="col-12 px-1">
						<announcement-item
							:id="parseInt(bannerInfo.id)"
							:title="bannerInfo.attributes.title"
							:image="bannerInfo.attributes.image"
							:has-url="bannerInfo.attributes.has_url"
							:url="bannerInfo.attributes.url"
							:mobile-image="bannerInfo.attributes.mobile_image" />
					</div>
				</div>
				<hr>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>

<script>
import Banner from '@/util/Banner';
import AnnouncementItem from './components/AnnouncementItem';

export default {
	name: 'UserAnnouncements',
	components: {
		AnnouncementItem,
	},
	data() {
		return {
			banners: new Banner(),
			removeBanner: new Banner(),
			alert: new this.$Alert(),
		};
	},
	computed: {
		loading() {
			return !!this.banners.data.loading;
		},
		errors() {
			return this.banners.data.errors.errors;
		},
		data() {
			try {
				const { data } = this.banners.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.banners.listBanners(this.$user.details().id);
	},
};
</script>
